import { Route, Routes } from "react-router-dom";

import "./App.scss";

import { ModuleEntity, ModulePermissionEntity } from "./config/defines";
import RouteAuth from "./components/RouteAuth";

import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import Avaliacoes from "./screens/Avaliacoes";
import AvaliacaoFormulario from "./screens/AvaliacaoFormulario";
import AvaliacaoDetalhes from "./screens/AvaliacaoDetalhes";
import AvaliacaoConfiguracoes from "./screens/AvaliacaoConfiguracoes";
import AvaliacaoLaudos from "./screens/AvaliacaoLaudos";
import Configuracoes from "./screens/Configuracoes";
import ConfiguracoesAvaliacoes from "./screens/ConfiguracoesAvaliacoes";
import ConfiguracoesParametros from "./screens/ConfiguracoesParametros";
import ParametroFormulario from "./screens/ParametroFormulario";
import ConfiguracoesDominios from "./screens/ConfiguracoesDominios";
import DominioFormulario from "./screens/DominioFormulario";
import Acessos from "./screens/Acessos";
import Grupos from "./screens/Grupos";
import GrupoFormulario from "./screens/GrupoFormulario";
import Usuarios from "./screens/Usuarios";
import UsuarioFormulario from "./screens/UsuarioFormulario";
import Empresas from "./screens/Empresas";
import EmpresaFormulario from "./screens/EmpresaFormulario";
import Clientes from "./screens/Clientes";
import ClienteFormulario from "./screens/ClienteFormulario";
import Unidades from "./screens/Unidades";
import UnidadeFormulario from "./screens/UnidadeFormulario";
import Page404 from "./screens/Page404";

function App() {
	return (
		<Routes>
			<Route path="/" element={<Login />} />
			<Route
				path="/dashboard"
				element={
					<RouteAuth>
						<Dashboard />
					</RouteAuth>
				}
			/>
			<Route
				path="/avaliacoes"
				element={
					<RouteAuth module={ModuleEntity.avaliacoes}>
						<Avaliacoes />
					</RouteAuth>
				}
			/>
			<Route
				path="/avaliacaoFormulario"
				element={
					<RouteAuth module={ModuleEntity.avaliacoes} permission={ModulePermissionEntity.editar}>
						<AvaliacaoFormulario />
					</RouteAuth>
				}
			>
				<Route
					index
					element={
						<RouteAuth module={ModuleEntity.avaliacoes} permission={ModulePermissionEntity.editar}>
							<AvaliacaoFormulario />
						</RouteAuth>
					}
				/>
				<Route
					path=":questionnaireId"
					element={
						<RouteAuth module={ModuleEntity.avaliacoes} permission={ModulePermissionEntity.editar}>
							<AvaliacaoFormulario />
						</RouteAuth>
					}
				/>
				<Route
					path=":questionnaireId/:id"
					element={
						<RouteAuth module={ModuleEntity.avaliacoes} permission={ModulePermissionEntity.editar}>
							<AvaliacaoFormulario />
						</RouteAuth>
					}
				/>
			</Route>
			<Route
				path="/avaliacaoDetalhes/:id"
				element={
					<RouteAuth module={ModuleEntity.avaliacoes} permission={ModulePermissionEntity.visualizar}>
						<AvaliacaoDetalhes />
					</RouteAuth>
				}
			/>
			<Route
				path="/configuracoes"
				element={
					<RouteAuth module={ModuleEntity.configuracoes}>
						<Configuracoes />
					</RouteAuth>
				}
			/>
			<Route
				path="/configuracoesAvaliacoes"
				element={
					<RouteAuth module={ModuleEntity.configuracoesAvaliacoes}>
						<ConfiguracoesAvaliacoes />
					</RouteAuth>
				}
			/>
			<Route
				path="/avaliacaoConfiguracoes"
				element={
					<RouteAuth module={ModuleEntity.configuracoesAvaliacoes} permission={ModulePermissionEntity.editar}>
						<AvaliacaoConfiguracoes />
					</RouteAuth>
				}
			>
				<Route
					index
					element={
						<RouteAuth module={ModuleEntity.configuracoesAvaliacoes} permission={ModulePermissionEntity.editar}>
							<AvaliacaoConfiguracoes />
						</RouteAuth>
					}
				/>
				<Route
					path=":id"
					element={
						<RouteAuth module={ModuleEntity.configuracoesAvaliacoes} permission={ModulePermissionEntity.visualizar}>
							<AvaliacaoConfiguracoes />
						</RouteAuth>
					}
				/>
			</Route>
			<Route
				path="/avaliacaoLaudos/:questionnaireId"
				element={
					<RouteAuth module={ModuleEntity.configuracoesAvaliacoes}>
						<AvaliacaoLaudos />
					</RouteAuth>
				}
			/>
			<Route
				path="/avaliacaoLaudos/:questionnaireId/:id"
				element={
					<RouteAuth module={ModuleEntity.configuracoesAvaliacoes}>
						<AvaliacaoLaudos />
					</RouteAuth>
				}
			/>
			<Route
				path="/configuracoesParametros"
				element={
					<RouteAuth module={ModuleEntity.configuracoesParametros}>
						<ConfiguracoesParametros />
					</RouteAuth>
				}
			/>
			<Route
				path="/parametroFormulario"
				element={
					<RouteAuth module={ModuleEntity.configuracoesParametros} permission={ModulePermissionEntity.editar}>
						<ParametroFormulario />
					</RouteAuth>
				}
			>
				<Route
					index
					element={
						<RouteAuth module={ModuleEntity.configuracoesParametros} permission={ModulePermissionEntity.editar}>
							<ParametroFormulario />
						</RouteAuth>
					}
				/>
				<Route
					path=":id"
					element={
						<RouteAuth module={ModuleEntity.configuracoesParametros} permission={ModulePermissionEntity.visualizar}>
							<ParametroFormulario />
						</RouteAuth>
					}
				/>
			</Route>
			<Route
				path="/configuracoesDominios"
				element={
					<RouteAuth module={ModuleEntity.configuracoesDominios}>
						<ConfiguracoesDominios />
					</RouteAuth>
				}
			/>
			<Route
				path="/dominioFormulario"
				element={
					<RouteAuth module={ModuleEntity.configuracoesDominios} permission={ModulePermissionEntity.editar}>
						<DominioFormulario />
					</RouteAuth>
				}
			>
				<Route
					index
					element={
						<RouteAuth module={ModuleEntity.configuracoesDominios} permission={ModulePermissionEntity.editar}>
							<DominioFormulario />
						</RouteAuth>
					}
				/>
				<Route
					path=":id"
					element={
						<RouteAuth module={ModuleEntity.configuracoesDominios} permission={ModulePermissionEntity.visualizar}>
							<DominioFormulario />
						</RouteAuth>
					}
				/>
			</Route>
			<Route
				path="/acessos"
				element={
					<RouteAuth module={ModuleEntity.acessos}>
						<Acessos />
					</RouteAuth>
				}
			/>
			<Route
				path="/grupos"
				element={
					<RouteAuth module={ModuleEntity.acessos}>
						<Grupos />
					</RouteAuth>
				}
			/>
			<Route
				path="/grupoFormulario"
				element={
					<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.editar}>
						<GrupoFormulario />
					</RouteAuth>
				}
			>
				<Route
					index
					element={
						<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.editar}>
							<GrupoFormulario />
						</RouteAuth>
					}
				/>
				<Route
					path=":id"
					element={
						<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.visualizar}>
							<GrupoFormulario />
						</RouteAuth>
					}
				/>
			</Route>
			<Route
				path="/usuarios"
				element={
					<RouteAuth module={ModuleEntity.acessos}>
						<Usuarios />
					</RouteAuth>
				}
			/>
			<Route
				path="/usuarioFormulario"
				element={
					<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.editar}>
						<UsuarioFormulario />
					</RouteAuth>
				}
			>
				<Route
					index
					element={
						<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.editar}>
							<UsuarioFormulario />
						</RouteAuth>
					}
				/>
				<Route
					path=":id"
					element={
						<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.visualizar}>
							<UsuarioFormulario />
						</RouteAuth>
					}
				/>
			</Route>
			<Route
				path="/empresas"
				element={
					<RouteAuth module={ModuleEntity.acessos}>
						<Empresas />
					</RouteAuth>
				}
			/>
			<Route
				path="/empresaFormulario"
				element={
					<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.editar}>
						<EmpresaFormulario />
					</RouteAuth>
				}
			>
				<Route
					index
					element={
						<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.editar}>
							<EmpresaFormulario />
						</RouteAuth>
					}
				/>
				<Route
					path=":id"
					element={
						<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.visualizar}>
							<EmpresaFormulario />
						</RouteAuth>
					}
				/>
			</Route>
			<Route
				path="/clientes"
				element={
					<RouteAuth module={ModuleEntity.acessos}>
						<Clientes />
					</RouteAuth>
				}
			/>
			<Route
				path="/clienteFormulario"
				element={
					<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.editar}>
						<ClienteFormulario />
					</RouteAuth>
				}
			>
				<Route
					index
					element={
						<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.editar}>
							<ClienteFormulario />
						</RouteAuth>
					}
				/>
				<Route
					path=":id"
					element={
						<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.visualizar}>
							<ClienteFormulario />
						</RouteAuth>
					}
				/>
			</Route>
			<Route
				path="/unidades"
				element={
					<RouteAuth module={ModuleEntity.acessos}>
						<Unidades />
					</RouteAuth>
				}
			/>
			<Route
				path="/unidadeFormulario"
				element={
					<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.editar}>
						<UnidadeFormulario />
					</RouteAuth>
				}
			>
				<Route
					index
					element={
						<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.editar}>
							<UnidadeFormulario />
						</RouteAuth>
					}
				/>
				<Route
					path=":id"
					element={
						<RouteAuth module={ModuleEntity.acessos} permission={ModulePermissionEntity.visualizar}>
							<UnidadeFormulario />
						</RouteAuth>
					}
				/>
			</Route>
			<Route path="*" element={<Page404 />} />
		</Routes>
	);
}

export default App;
